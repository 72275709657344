<template>
  <section class="home_filler filler_section row_padding download">
    <div class="filler_row main_container">
      <div class="filler_img_col">
        <div class="filler_img_block">
          <img src="../assets/images/filler_img.png" class="filler_img" />
        </div>
      </div>
      <div class="filler_col">
        <div class="filler_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading tc br">
              Download Our App
              <span
                >on your Android or Apple device to book your choice of villa
                today!</span
              >
            </p>
            <!-- <p class="small_body_font tc">Download our app on your Android or Apple device to book your choice of villa
              today!</p> -->
            <div class="ads_img_block">
              <a :href="app_android" target="_blank" class="filler_btn_01"
                ><img
                  src="../assets/images/gp-img.png"
                  class="ads_img_01 ads_img"
              /></a>
              <a :href="app_ios" target="_blank" class="filler_btn_02"
                ><img
                  src="../assets/images/as-img.png"
                  class="AS_btn_02 ads_img"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Download",
  components: {},
  data() {
    return {
      app_android: process.env.VUE_APP_ANDROID,
      app_ios: process.env.VUE_APP_IOS,
    };
  },
  mounted() {},
};
</script>
<style scoped>
@media all and (max-width: 991px) {
  section.home_filler.filler_section.row_padding.download {
    margin: 8px;
  }
}
</style>
